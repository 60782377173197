import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import AppLayout from '../../layout/components/AppLayout'
import withAlert from '../../hoc/withAlert'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';

import authServices from '../authServices';
import { Link } from 'react-router-dom'
import aplicantAuthServices from '../aplicantAuthServices'


function ApplicantChangePassword({AppAlert}) {

    const authUser = useSelector((state) => state.auth)
      

    const initialValues = {
        oldPassword:'',
        newPassword:'',
        confirmPassword:'',
    }
    
    const validationRule = Yup.object({
        oldPassword: Yup.string().required("Required"),
        newPassword: Yup.string().required("Required"),
        confirmPassword: Yup.string().required("Required")
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });

    const onSubmit = (values)=>{
        console.log(values);
        aplicantAuthServices.changePassword(values).then(res=>{
            console.log(res);
            AppAlert(res.msg);
        }).catch(error=>{
            console.log(error);
            AppAlert(error.msg,"error");
        })
    }

    return (
        <AppLayout>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="card mt-5">
                            <div className="card-body text-center">
                                
                                <div className="row">

                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationRule}
                                        onSubmit={onSubmit}
                                        enableReinitialize validateOnMount
                                        >
                                        {
                                            (formik) => {
                                            //console.log(formik.values);
                                            return (
                                                <Form>

                                                    <div className="col-md-12 Text-center">Change Password</div>

                                                    <FormikControl control="input" formik={formik} type="password"
                                                        label="Old Password" name="oldPassword"
                                                        className="col-sm-12 col-xs-12 mt-4"
                                                    />

                                                    <FormikControl control="input" formik={formik} type="password"
                                                        label="New Password" name="newPassword"
                                                        className="col-sm-12 col-xs-12 mt-4"
                                                    />

                                                    <FormikControl control="input" formik={formik} type="password"
                                                        label="Confirm Password" name="confirmPassword"
                                                        className="col-sm-12 col-xs-12 mt-4"
                                                    />


                                                    {/*JSON.stringify(formik.values) */}
                                                    {/* <Link to="/auth/register" className="btn btn-register">Register</Link> */ }
                                                    <button type="submit" className="btn btn-primary mt-4 float-end ">Change Password</button>
                                                    <Link to="/" className="btn btn-secondary mt-4 me-2 float-end">Cancel</Link>
                                                </Form>
                                            )
                                            }
                                        }
                                    </Formik>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default  withAlert(ApplicantChangePassword);