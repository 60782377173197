import {useEffect, useState} from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { startSession } from '../../redux/authStore';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';
import { Link } from 'react-router-dom';
import aplicantAuthServices from '../aplicantAuthServices';

function ApplicantLogin(props) {
    
    const [error,setError] = useState(false);
    const [errorMsg,setErrorMsg] = useState("");

    const [otpSent,setSentOtpStatus] = useState(false);
    const [successMsg,setSuccessMsg] = useState("");
    
    const [otpError,setOtpError] = useState(false);

    const initialValues = {
        email :'',
        otp:''
    }
    
    const validationRequestOTPRule = Yup.object({
        email: Yup.string().required("Required")
    });

    const validationVerifyOTPRule = Yup.object({
        email: Yup.string().required("Required"),
        otp: Yup.string().required("Required")
    });

    const  validationRule = () => {
        if(otpSent){
            return validationVerifyOTPRule;
        } else {
            return validationRequestOTPRule;
        }
    }

    const navigate = useNavigate();
    const { startSession } = props;
    
    useEffect(()=>{  
        if(localStorage.authUser){
        startSession();
        navigate("/");
        }
    },[])


    const requestOTP = (values) =>{
        setError(false);
        setSentOtpStatus(false);
        aplicantAuthServices.requestOtp(values).then( (res) => {
            setSentOtpStatus(true);
            setSuccessMsg(res.data.msg);
        }).catch( error =>{
            setError(true);
            setErrorMsg(error.msg);
        })
    }

    const verifyOTP = (values) =>{
        setError(false);
        setOtpError(false);
        aplicantAuthServices.verifyOtp(values).then( (res) => {
            console.log("here @ success",res)
            startSession();
            navigate("/");
        }).catch( error =>{
            setOtpError(true);
        })
    }


    const onSumbit = (values, onSubmitProps) => {
        if(otpSent){
            verifyOTP(values);
        } else {
            requestOTP(values);
        }
    }

    return (
        <>
        <nav className="navbar shadow navbar-expand-lg navbar-light bg-light sticky-top">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img src="/global-logo.png" height="50" alt="" />
                </Link>
            </div>
        </nav>
        <div className="container-fluid">
            <div className="row mt-5">
                <div className="col-md-6 offset-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-floating mb-3">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationRule}
                                    onSubmit={onSumbit }
                                    enableReinitialize validateOnMount
                                >
                                    {
                                    (formik) => {
                                        //console.log(formik.values);
                                        return (
                                        <Form>
                                            {error && 
                                            <div className="col-md-12">
                                                <div className="alert alert-danger">
                                                <strong>!</strong> {errorMsg}
                                                </div>
                                            </div>
                                            }

                                            {otpSent && 
                                            <div className="col-md-12">
                                                <div className="alert alert-success">
                                                <strong>!</strong> {successMsg}
                                                </div>
                                            </div>
                                            }

                                            

                                            <FormikControl control="input" formik={formik} type="email"
                                                label="Your Email ID" name="email"
                                                className="col-sm-12 col-xs-12 mt-4"
                                            />

                                            {otpSent && 
                                                <FormikControl control="input" formik={formik} type="text"
                                                    label="OTP" name="otp"
                                                    className="col-sm-12 col-xs-12 mt-4"
                                                />
                                            }

                                            {otpError && 
                                            <div className="col-md-12">
                                                <div className="alert alert-danger">
                                                <strong>!</strong> Invalid OTP
                                                </div>
                                            </div>
                                            }
                                            
                                            {
                                                !otpSent ? 
                                                    <button type="submit" className="btn btn-primary mt-4 float-end btn-lg">Request OTP</button>
                                                    :
                                                    <button type="submit" className="btn btn-primary mt-4 float-end btn-lg">Verify OTP</button>
                                            }
                                            {/* <Link to="/auth/forgot-password" className="btn btn-link mt-4 btn-lg float-end">Forgot Password</Link> */}

                                        </Form>
                                        )
                                    }
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) =>{
    return {
      auth:state.auth
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      startSession : () => {
        dispatch( startSession());
      }
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantLogin);