import React, { useEffect, useState } from 'react'
import moment from 'moment';
import AppLayout from '../../../../layout/components/AppLayout'
import { LinearProgress } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';
import useMasterList from '../../../../hooks/useMasterList';
import withAlert from '../../../../hoc/withAlert';
import applicationServices from '../applicationServices';
import { useLocation, useNavigate } from 'react-router';
import ApplicationSteps from '../components/ApplicationSteps';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ApplicationFormPage({AppAlert}) {

    let query = useQuery();
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);

    const [masters,getMasterLabel] = useMasterList(["area","class","gender","yes-no","state", "occupation","entry-type",
                                    "osp","game","game-boys","category","category-haryana","religion","nationality","caste"
                                    ]);

    /* Form Work  */
    const initialValues = {
        firstName:"",
        middleName:"",
        lastName:"",
        class:5,
        gender:"",
        aadhar:"",
        identificationMark1:"",
        identificationMark2:"",
        
        dob:moment().format("YYYY-MM-DD"),
        birthPlace:"",
        birthCertificateNo:"",
        birthCertificateIssueDate:moment().format("YYYY-MM-DD"),
        haryanaDomicile:1,

        familyId:"",

        fatherName:"",
        fatherPhone:"",
        fatherOccupation:"",
        motherName:"",
        motherPhone:"",
        motherOccupation:"",

        addressLine1:"",
        addressLine2:"",
        city:"",
        state:"",
        pincode:"",
        area:"", 

        entryType:1,

        osp:1,
        game:1,
        category:"",
        religion:1,
        nationality:1,
        caste:1,
    }

    const initialValuesTestData = {
        firstName:"Daini",
        middleName:"G",
        lastName:"Dev",
        class:1,
        gender:1,
        aadhar:"1234-5678-9101",
        identificationMark1:"AAA",
        identificationMark2:"",
        
        dob:moment().format("YYYY-MM-DD"),
        birthPlace:"Delhi",
        birthCertificateNo:"11223344",
        birthCertificateIssueDate:moment().format("YYYY-MM-DD"),
        haryanaDomicile:1,

        familyId:"AABB1234",

        fatherName:"Dev",
        fatherPhone:"9899899891",
        fatherOccupation:1,
        motherName:"Devi",
        motherPhone:"9879879876",
        motherOccupation:2,

        addressLine1:"A-502",
        addressLine2:"",
        city:"Rohtak",
        state:1,
        pincode:"124001",
        area:2, 

        entryType:1,
        osp:1,
        game:1,
        category:1,
        religion:2,
        nationality:1,
        caste:1,
    }

    const validationRule = Yup.object({
        
        class: Yup.string().required("Required"),
        
        //dob: Yup.date().required("Required").test(),

        
        dob: Yup.date().required("Required").when(['class'],(clas,schema,val)=>{
            //console.log("here in class ",clas);
            //console.log("val",val.value);
            //let diff = moment().diff(moment(val.value),'days');
            //console.log("diff in days",diff)

            let minAgeForClass1  = 5;
            
            clas = parseInt(clas);
            

            let minAgeForSelectedClass = minAgeForClass1+clas-1;
            let maxAgeForSelectedClass = minAgeForClass1+clas;
            if(clas == 11){
                minAgeForSelectedClass = minAgeForSelectedClass-2
            }

            let dobMinDate = moment("2023-04-01").subtract(maxAgeForSelectedClass,'year').format("YYYY-MM-DD")
            let dobMaxDate = moment("2024-03-31").subtract(minAgeForSelectedClass,'year').format("YYYY-MM-DD")

            console.log("minagefor selectedclass ", minAgeForSelectedClass);
            console.log('Dob min date',dobMinDate);
            console.log("Dob max date ", dobMaxDate);

            let errorMsg = "Date of Birth should be between "+ moment(dobMinDate).format("ll")+" and "+moment(dobMaxDate).format("ll");
            
            return Yup.date().min(dobMinDate,errorMsg).max(dobMaxDate,errorMsg);
            //console.log("endDate", endDate);

        }),
        

        birthCertificateNo: Yup.string().required("Required"),
        birthCertificateIssueDate: Yup.date().required("Required").when(['dob'],(dob,schema,val)=>{
            console.log("birthcertificate dob",dob);
            //console.log(val.value);
            

            let regMinDate = moment(dob).add(0,'day').format("YYYY-MM-DD")
            let regMaxDate = moment(dob).add(2,'year').format("YYYY-MM-DD")

            console.log("reg min date",regMinDate);
            console.log("reg max date",regMaxDate);


            let errorMsg = "Birth Certificate registration date should not be greater than 2 years from Date of Birth";

            return Yup.date().min(regMinDate,errorMsg).max(regMaxDate,errorMsg);

            /*
            let startDate =  moment(dob);
            let endDate =  moment(val.value);
            let diff = endDate.diff(startDate,'days')
            console.log("diff is : ",diff);
            */
            

            
        }),


        firstName: Yup.string().required("Required"),
        //middleName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        
        gender: Yup.string().required("Required"),
        aadhar: Yup.string().required("Required"),
        identificationMark1: Yup.string().required("Required"),
        //identificationMark2: Yup.string().required("Required"),
        
        
        birthPlace: Yup.string().required("Required"),
        haryanaDomicile: Yup.string().required("Required"),
        category: Yup.string().required("Required"),

        familyId: Yup.string().when(['haryanaDomicile'],(haryanaDomicile,schema,val)=>{
            if(haryanaDomicile==1){
                return Yup.string().required("Required")
            }
        }),

        fatherName: Yup.string().required("Required"),
        fatherPhone: Yup.string().matches(new RegExp('[0-9]{10}'), 'Phone number is not valid').required("Required"),
        fatherOccupation: Yup.string().required("Required"),
        motherName: Yup.string().required("Required"),
        motherPhone: Yup.string().matches(new RegExp('[0-9]{10}'), 'Phone number is not valid').required("Required"),
        motherOccupation: Yup.string().required("Required"),


        addressLine1: Yup.string().required("Required"),
        //addressLine2: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
        pincode: Yup.string().required("Required"),
        area: Yup.string().required("Required"),

        entryType: Yup.string().required("Required"),

        osp: Yup.string().when(['entryType'],(entryType,schema,val)=>{
            if(entryType==2){
                return Yup.string().required("Required")
            }
        }),
        game: Yup.string().when(['entryType'],(entryType,schema,val)=>{
            if(entryType==2){
                return Yup.string().required("Required")
            }
        }),
        

        //religion: Yup.string().required("Required"),
        //nationality: Yup.string().required("Required"),
        //caste: Yup.string().required("Required"),

    });


    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        setSavingStatus(true)
        applicationServices.save(values).then(res =>{
            console.log(res);
            if(query.get("applicationId")){
                navigate("/application/documents/"+query.get("applicationId"))
            } else{
                navigate("/application/documents/"+res.id)
            }
            AppAlert(res.msg);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }




    const updateData = ()=>{
        applicationServices.getById(query.get("applicationId")).then(res=>{
            let data = res.data;
            if(data==null){
                AppAlert("Something went wrog","error");
            }
            if(data.requestStatus !== 1){
                navigate("/application/"+data.id)
            }
            let autoFill = {
                id:data.id,
                firstName:data.firstName,
                middleName:data.middleName,
                lastName:data.lastName,
                class:data.class,
                gender:data.gender,
                aadhar:data.aadhar,
                identificationMark1:data.identificationMark1,
                identificationMark2:data.identificationMark2,
                dob:data.dob,
                birthPlace:data.birthPlace,
                birthCertificateNo:data.birthCertificateNo,
                birthCertificateIssueDate:data.birthCertificateIssueDate,
                familyId:data.familyId,
                haryanaDomicile:data.haryanaDomicile,
                fatherName:data.fatherName,
                fatherPhone:data.fatherPhone,
                fatherOccupation:data.fatherOccupation,
                motherName:data.motherName,
                motherPhone:data.motherPhone,
                motherOccupation:data.motherOccupation,
                addressLine1:data.addressLine1,
                addressLine2:data.addressLine2,
                city:data.city,
                state:data.state,
                pincode:data.pincode,
                area:data.area,
                entryType:data.entryType,
                osp:data.osp,
                game:data.game,
                category:data.category,
                religion:data.religion,
                nationality:data.nationality,
                caste:data.caste,
            }
            setFormValues(autoFill);
        })
        
    }



    useEffect(()=>{
        if(query.get("applicationId") && query.get("applicationId") != ""){
            updateData();
        }
    },[])

    return (
        <AppLayout>
            <div className="container">
                <div className="row mt-2">
                    <ApplicationSteps activeStep={0} />
                </div>

                <div className="row mt-2">
                    <div className="col-md-12">
                        
                                <Formik
                                    initialValues={formValues || initialValues}
                                    validationSchema={validationRule}
                                    onSubmit={onSubmit}
                                    enableReinitialize validateOnMount
                                >
                                    {
                                        (formik) => {
                                            //console.log(formik.values);
                                            return (
                                                <Form>
                                                    <div className="row p-2 border rounded p-2 mb-2">

                                                        <h4>Eligblity Details</h4>
                                                        

                                                        {masters && 
                                                        <div className="col-md-6 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Class*" name="class"  
                                                                options={masters['class']} multiple={false} 
                                                            />
                                                        </div>
                                                        }

                                                        <div className="col-md-6 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="date" 
                                                                label="Date of Birth*" name="dob" 
                                                            />
                                                        </div>

                                                        <div className="col-md-6 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="date" 
                                                                label="Birth Certificate Registration Date*" name="birthCertificateIssueDate" 
                                                            />
                                                        </div>

                                                        <div className="col-md-6 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Birth Certificate No*" name="birthCertificateNo" 
                                                            />
                                                        </div>

                                                        <h4>Student Details</h4>
                                                        <div className="col-md-12">
                                                            <div className="text-primary"><small>It is mandatory to add surname in application form, if you don't have surname in your birth certificate you can add your surname name in the application form.</small></div>
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="First Name*" name="firstName" 
                                                            />
                                                        </div>

                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Middle Name" name="middleName" 
                                                            />
                                                        </div>

                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Last Name*" name="lastName" 
                                                            />
                                                        </div>

                                                        

                                                        {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Gender*" name="gender"  
                                                                options={masters['gender']} multiple={false} 
                                                            />
                                                        </div>
                                                        }

                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="UID / Aadhar no*" name="aadhar" 
                                                            />
                                                        </div>

                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Identification Mark 1 *" name="identificationMark1" 
                                                            />
                                                        </div>

                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Identification Mark 2" name="identificationMark2" 
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="row p-2 border rounded p-2 mb-2">
                                                        

                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Birth Place*" name="birthPlace" 
                                                            />
                                                        </div>

                                                        {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Haryana Domicile*" name="haryanaDomicile"  
                                                                options={masters['yes-no']} multiple={false} 
                                                            />
                                                        </div>
                                                        }

                                                        { masters && formik.values.haryanaDomicile == 1 &&
                                                            <div className="col-md-3 col-xs-12 p-2" >    
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Category*" name="category"  
                                                                    options={masters['category-haryana']} multiple={false} 
                                                                />
                                                            </div>
                                                        }

                                                        { masters && formik.values.haryanaDomicile == 2 &&
                                                            <div className="col-md-3 col-xs-12 p-2" >    
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Category*" name="category"  
                                                                    options={masters['category']} multiple={false} 
                                                                />
                                                            </div>
                                                        }

                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label={formik.values.haryanaDomicile==1?"Family ID*":"Family ID"} name="familyId" 
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="row p-2 border rounded p-2 mb-2">
                                                        <h4>Parents Details</h4>
                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Father Name*" name="fatherName" 
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Father Phone*" name="fatherPhone" 
                                                            />
                                                        </div>
                                                        {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Father Occupation*" name="fatherOccupation"  
                                                                options={masters['occupation']} multiple={false} 
                                                            />
                                                        </div>
                                                        }

                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Mother Name*" name="motherName" 
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Mother Phone*" name="motherPhone" 
                                                            />
                                                        </div>
                                                        {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Mother Occupation*" name="motherOccupation"  
                                                                options={masters['occupation']} multiple={false} 
                                                            />
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className="row p-2 border rounded p-2 mb-2">
                                                        <h4>Address Details</h4>
                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Address Line 1*" name="addressLine1" 
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Address Line 2" name="addressLine2" 
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="City*" name="city" 
                                                            />
                                                        </div>
                                                        {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="State*" name="state"  
                                                                options={masters['state']} multiple={false} 
                                                            />
                                                        </div>
                                                        }
                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Pincode*" name="pincode" 
                                                            />
                                                        </div>
                                                        {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Area*" name="area"  
                                                                options={masters['area']} multiple={false} 
                                                            />
                                                        </div>
                                                        }
                                                    </div>

                                                    {formik.values.class > 5 &&
                                                    <div className="row p-2 border rounded p-2 mb-2">
                                                        <h4>Sports & Quota Details</h4>

                                                        {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Outstanding sports person / Lateral Entry *" name="entryType"  
                                                                options={masters['entry-type']} multiple={false} 
                                                            />
                                                        </div>
                                                        }

                                                        {masters && formik.values.entryType == 2 && <>
                                                            <div className="col-md-4 col-xs-12 p-2" >    
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="OSP (Outstanding sports person category) *" name="osp"  
                                                                    options={masters['osp']} multiple={false} 
                                                                />
                                                            </div>
                                                            {formik.values.gender == 1 &&
                                                                <div className="col-md-4 col-xs-12 p-2" >    
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Game*" name="game"  
                                                                        options={masters['game-boys']} multiple={false} 
                                                                    />
                                                                </div>
                                                            }
                                                            {formik.values.gender == 2 &&
                                                                <div className="col-md-4 col-xs-12 p-2" >    
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Game*" name="game"  
                                                                        options={masters['game']} multiple={false} 
                                                                    />
                                                                </div>
                                                            }
                                                            
                                                    </>}


                                                        

                                                    {/*
                                                        {masters && 
                                                            <div className="col-md-4 col-xs-12 p-2" >    
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Religion*" name="religion"  
                                                                    options={masters['religion']} multiple={false} 
                                                                />
                                                            </div>
                                                        }
                                                        {masters && 
                                                            <div className="col-md-4 col-xs-12 p-2" >    
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Nationality*" name="nationality"  
                                                                    options={masters['nationality']} multiple={false} 
                                                                />
                                                            </div>
                                                        }
                                                        {masters && 
                                                            <div className="col-md-4 col-xs-12 p-2" >    
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Caste*" name="caste"  
                                                                    options={masters['caste']} multiple={false} 
                                                                />
                                                            </div>
                                                        }
                                                    */}

                                                    </div>
                                                    }
                                                    <div className="row">
                                                    { /*  JSON.stringify(formik.values) */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 p-4">
                                                            {savingInProgress && <LinearProgress/> }
                                                            {!savingInProgress && 
                                                                <>
                                                                    <button className="btn btn-success float-end my-4" type="submit" >Save & Continue</button>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </Form>
                                            )
                                        }
                                    }
                                </Formik>
                            </div>
                        </div>
                    
            </div>
        </AppLayout>
    )
}

export default withAlert(ApplicationFormPage)