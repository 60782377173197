import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router';
import AppLayout from '../../../../layout/components/AppLayout';
import applicationServices from '../applicationServices';
import { LinearProgress } from "@mui/material";
import AwsImageInline from '../../../../components/InlineEdit/AwsImageInline';
import AwsDocumentInline from '../../../../components/InlineEdit/AwsDocumentInline';
import withAlert from '../../../../hoc/withAlert';
import ApplicationSteps from '../components/ApplicationSteps';
import { Link } from 'react-router-dom';
import { CheckCircle } from '@mui/icons-material';


function ApplicationDocumentPage( {AppAlert}) {
    let { id } = useParams();
    const [savingInProgress, setSavingStatus] = useState(false);
    const navigate = useNavigate();

    const [applicationDetails,setApplicationDetails] = useState(null);
    const getApplicationDetails = () =>{
        applicationServices.getById(id).then(res=>{
            setApplicationDetails(res.data);
        })
    }

    useEffect(()=>{
        getApplicationDetails();
    },[id]);

    useEffect(()=>{
        if(applicationDetails && applicationDetails.requestStatus !== 1){
            navigate("/application/"+applicationDetails.id)
        }
    },[applicationDetails])

    return (
        <AppLayout>
            <div className="container">
                {applicationDetails &&
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <ApplicationSteps activeStep={1} />
                        </div>
                    </div>
                }
                {applicationDetails &&
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-dark fw-bold text-white"><span className="text-warning">{applicationDetails.firstName} {applicationDetails.lastName}'s </span> Documents</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <h5>Student Photo 
                                                {applicationDetails.studentPhoto !== null ? <CheckCircle color="success" /> :<span className="text-danger">*</span>}
                                            </h5>
                                            <AwsImageInline
                                                label="Student Photo"
                                                name="studentPhoto"
                                                value={applicationDetails.studentPhoto}
                                                editId={applicationDetails.id}
                                                updateInline={applicationServices.updateInline}
                                                AppAlert={AppAlert}
                                                module="student-photo"
                                                fileId={applicationDetails.id}
                                                onSuccess={getApplicationDetails}
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <h5>Sports Certificate <span className="text-primary">(Required if you apply for OSP Category)</span></h5>
                                            <AwsDocumentInline
                                                label="Sports Certificate"
                                                name="sportsCertificate"
                                                value={applicationDetails.sportsCertificate}
                                                editId={applicationDetails.id}
                                                updateInline={applicationServices.updateInline}
                                                AppAlert={AppAlert}
                                                module="sports-certificate"
                                                fileId={applicationDetails.id}
                                                onSuccess={getApplicationDetails}
                                            />
                                            <hr/>
                                            <h5>Birth Certificate
                                                {applicationDetails.birthCertificate !== null ? <CheckCircle color="success" /> :<span className="text-danger">*</span>}
                                            </h5>
                                            <AwsDocumentInline
                                                label="Birth Certificate *"
                                                name="birthCertificate"
                                                value={applicationDetails.birthCertificate}
                                                editId={applicationDetails.id}
                                                updateInline={applicationServices.updateInline}
                                                AppAlert={AppAlert}
                                                module="birth-certificate"
                                                fileId={applicationDetails.id}
                                                onSuccess={getApplicationDetails}
                                            />
                                            <hr/>
                                            <h5>Student Signature
                                                {applicationDetails.studentSignature !== null ? <CheckCircle color="success" /> :<span className="text-danger">*</span>}
                                            </h5>
                                            <AwsImageInline
                                                label="Student Signature *"
                                                name="studentSignature"
                                                value={applicationDetails.studentSignature}
                                                editId={applicationDetails.id}
                                                updateInline={applicationServices.updateInline}
                                                AppAlert={AppAlert}
                                                module="student-signature"
                                                fileId={applicationDetails.id}
                                                onSuccess={getApplicationDetails}
                                                thumb={false}
                                            />
                                            <hr/>
                                            <h5>Parent Signature
                                                {applicationDetails.parentSignature !== null ? <CheckCircle color="success" /> :<span className="text-danger">*</span>}
                                            </h5>
                                            <AwsImageInline
                                                label="Parent Signature *"
                                                name="parentSignature"
                                                value={applicationDetails.parentSignature}
                                                editId={applicationDetails.id}
                                                updateInline={applicationServices.updateInline}
                                                AppAlert={AppAlert}
                                                module="parent-signature"
                                                fileId={applicationDetails.id}
                                                onSuccess={getApplicationDetails}
                                                thumb={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">{ /* JSON.stringify(applicationDetails) */}</div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {savingInProgress && <LinearProgress/> }
                                            {!savingInProgress && 
                                                <>
                                                <Link to={"/application/form?applicationId="+applicationDetails.id} className="btn btn-warning float-start my-4" type="button" >Back</Link>
                                                    {
                                                        applicationDetails.studentPhoto !== null &&
                                                        //applicationDetails.sportsCertificate !== null &&
                                                        applicationDetails.birthCertificate !== null &&
                                                        applicationDetails.studentSignature !== null &&
                                                        applicationDetails.parentSignature !== null  &&
                                                            <Link to={"/application/payment/"+applicationDetails.id} className="btn btn-success float-end my-4" type="button" >Continue To Payment</Link>
                                                    }


                                                    {(
                                                        applicationDetails.studentPhoto === null ||
                                                        //applicationDetails.sportsCertificate === null ||
                                                        applicationDetails.birthCertificate === null ||
                                                        applicationDetails.studentSignature === null ||
                                                        applicationDetails.parentSignature === null
                                                    ) &&
                                                            <button className="btn btn-success float-end my-4" type="button" disabled={true} >Continue To Payment</button>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default withAlert(ApplicationDocumentPage)



/*

<TextInline
                                        name="firstName" 
                                        editId={applicantDetails.id}
                                        value={applicantDetails.firstName}
                                        updateInline={applicantProfileServices.updateInline}
                                    />

*/
