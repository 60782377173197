import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router'
import AdminLogin from '../auth/pages/AdminLogin'
import ApplicantLogin from '../auth/pages/ApplicantLogin'
import ApplicantRegister from '../auth/pages/ApplicantRegister'


import Logout from '../auth/pages/Logout'

import UserListPage from '../users/pages/UserListPage'
import UserRolePage from '../users/pages/UserRolePage'
import SystemMasterListPage from '../master/pages/SystemMasterListPage'
import SchoolSettingsPage from '../app/settings/pages/SchoolSettingsPage'


import LandingPage from '../landing/pages/LandingPage'
import { useSelector } from 'react-redux'


import ApplicantDashboard from '../dashboard/pages/ApplicantDashboard'
import AdminDashboard from '../dashboard/pages/AdminDashboard'
import ApplicantChangePassword from '../auth/pages/ApplicantChangePassword'
import ApplicantListPage from '../app/admin/applicant/pages/ApplicantListPage'
import ApplicantDetailsPage from '../app/admin/applicant/pages/ApplicantDetailsPage'
import ApplicantProfilePage from '../app/applicant/profile/pages/ApplicantProfilePage'
import ApplicantForgotPassword from '../auth/pages/ApplicantForgotPassword'
import ApplicationFormPage from '../app/applicant/application/pages/ApplicationFormPage'
import ApplicationDetailsPage from '../app/applicant/application/pages/ApplicationDetailsPage'
import ApplicationDocumentPage from '../app/applicant/application/pages/ApplicationDocumentPage'
import ApplicationPaymentPage from '../app/applicant/application/pages/ApplicationPaymentPage'
import ApplicationPaymentStatusPage from '../app/applicant/application/pages/ApplicationPaymentStatusPage'
import ApplicationListPage from '../app/admin/application/pages/ApplicationListPage'
import AdminApplicationDetailsPage from '../app/admin/application/pages/AdminApplicationDetailsPage'
import RegistrationClosedPage from '../app/applicant/application/pages/RegistrationClosedPage'
import AdminAdmitCardPage from '../app/admin/application/pages/AdminAdmitCardPage'
import StudentAdmitCardPage from '../app/applicant/application/pages/StudentAdmitCardPage'
import DownloadAdmitCardPage from '../app/applicant/application/pages/DownloadAdmitCardPage'



export default function AppRoutes() {

    const authUser = useSelector((state) => state.auth)
    
    const [userRole,setUserRole] = useState(0); 
    const getUserRole = () =>{
        if(authUser){
            let user_role = authUser['role'] === undefined ? 0 : authUser['role']
            setUserRole(user_role);
        }
    }
    
    useEffect(()=>{
        getUserRole();
    },[authUser])
    


    
    return (
    <>
        <Routes>

            <Route path="/auth/login" element={ <ApplicantLogin /> } />
            <Route path="/auth/register" element={ <ApplicantRegister /> } />
            <Route path="/auth/forgot-password" element={ <ApplicantForgotPassword /> } />
            <Route path="/auth/admin/login" element={ <AdminLogin /> } />
            <Route path="/auth/logout" element={ <Logout/> } />

            {userRole === 0 &&  <Route path="/" element={ <LandingPage/> } /> }

            {/* Admin Routes */}
            {userRole === 1 &&  <Route path="/" element={ <AdminDashboard /> } /> }
            {userRole === 1 && <Route path="/dashboard" element={ <AdminDashboard /> } /> }
            {userRole === 1 && <Route path="/system-master/list" element={ <SystemMasterListPage /> } /> }
            {userRole === 1 && <Route path="/settings/school" element={ <SchoolSettingsPage /> } /> }
            {userRole === 1 && <Route path="/user/list" element={ <UserListPage/> } /> }
            {userRole === 1 && <Route path="/user/role" element={ <UserRolePage/> } /> }

            {userRole === 1 && <Route path="/applicant/list" element={ <ApplicantListPage /> } /> }
            {userRole === 1 && <Route path="/applicant/details/:id" element={ <ApplicantDetailsPage/> } /> }

            {userRole === 1 && <Route path="/application/list/:listType" element={ <ApplicationListPage /> } /> }
            {userRole === 1 && <Route path="/application/details/:id" element={ <AdminApplicationDetailsPage /> } /> }
            {userRole === 1 && <Route path="/admit-card/:id" element={ <AdminAdmitCardPage/>  } /> }

            {/* Applicant Routes */}
            {userRole === 4 &&  <Route path="/" element={ <ApplicantDashboard />} /> }
            {userRole === 4 &&  <Route path="/dashboard" element={ <ApplicantDashboard /> } /> }
            {userRole === 4 &&  <Route path="/my/profile" element={ <ApplicantProfilePage /> } /> }
            {userRole === 4 &&  <Route path="/auth/change-password" element={ <ApplicantChangePassword /> } /> }
            {userRole === 4 &&  <Route path="/application/:id" element={ <ApplicationDetailsPage />  } /> }
            
            

            {/* thse are duplicate Routes to close registration 
            {userRole === 4 &&  <Route path="/application/form" element={ <RegistrationClosedPage/>  } /> }
            {userRole === 4 &&  <Route path="/application/documents/:id" element={ <RegistrationClosedPage />  } /> }
            {userRole === 4 &&  <Route path="/application/payment/:id" element={ <RegistrationClosedPage />  } /> }
            {userRole === 4 &&  <Route path="/payment/status/:paymentRequestId" element={ <RegistrationClosedPage /> } /> }

            */}

                
            {userRole === 4 &&  <Route path="/application/form" element={ <ApplicationFormPage/>  } /> }
            {userRole === 4 &&  <Route path="/application/documents/:id" element={ <ApplicationDocumentPage />  } /> }
            {userRole === 4 &&  <Route path="/application/payment/:id" element={ <ApplicationPaymentPage />  } /> }
            {userRole === 4 &&  <Route path="/payment/status/:paymentRequestId" element={ <ApplicationPaymentStatusPage />  } /> }
            {userRole === 4 && <Route path="/admit-card/:id" element={ <StudentAdmitCardPage />  } /> }


            <Route path="/admit-card/download" element={ <DownloadAdmitCardPage/>  } /> 

            

        </Routes>
    </>
    )
}