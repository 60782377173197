import React from 'react'
import { LinearProgress, Step, StepLabel, Stepper } from "@mui/material";

function ApplicationSteps({activeStep}) {
    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            <Step>
                <StepLabel>Student Details</StepLabel>
            </Step>
            <Step>
                <StepLabel>Documents</StepLabel>
            </Step>
            <Step>
                <StepLabel>Payments</StepLabel>
            </Step>
        </Stepper>
    )
}

export default ApplicationSteps