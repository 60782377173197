import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AppLayout from '../../layout/components/AppLayout'
import adminDashboardServices from '../adminDashboardServices';



function AdminDashboard() {

    

    return (
        <AppLayout>
            <div className="container-fluid">
                Admin dashboard
            </div>
      </AppLayout>
    )
}

export default AdminDashboard