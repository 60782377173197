import { Cancel, CancelRounded, CheckCircle } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent,  IconButton } from '@mui/material';
import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import withAlert from '../../../../hoc/withAlert';
import useMasterList from '../../../../hooks/useMasterList';
import AppLayout from  '../../../../layout/components/AppLayout'
import adminApplicationServices from '../adminApplicationServices';
import ApproveWithSBI from '../components/ApproveWithSBI';


const LabelValue= ({label,value}) =>{
    return(
        <div className="row">
            <div className="col-md-6"><strong>{label} : </strong></div>
            <div className="col-md-6"><div><span>{(value === null || value === "" ) ? "--NA--" : value }</span></div></div>
        </div>
    )
}

function AdminApplicationDetailsPage({AppAlert}) {

    let { id } = useParams();
    const [masters,getMasterLabel]  =  useMasterList(["area","class", "entry-type","gender","yes-no","state", "occupation","osp","game","game-boys","category",,"category-haryana","religion","nationality","caste"]);

    const [applicationDetails,setApplicationDetails]    = useState(null);
    const [loading, setLoadingStatus]           = useState(false);

    const [dialogStatus, setDialogStatus] = useState(false);
    const [SBIDialogStatus, setSBIDialogStatus] = useState(false);

    const getApplicationDetails = () =>{
        setLoadingStatus(true);
        adminApplicationServices.getById(id).then(res=>{
            setApplicationDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getApplicationDetails();
    },[id])

    const confirmPayment = (requestStatus) =>{
        setLoadingStatus(true);
        let data = {
            id:applicationDetails.id,
            requestStatus:requestStatus,
        }
        adminApplicationServices.updateRequestStatus(data).then(res=>{
            setDialogStatus(false);
            getApplicationDetails();
        }).catch(error=>{
            console.log(error);
        }).finally(()=>{
            setLoadingStatus(false)
        })
        

    }

    return (
        <AppLayout>
            <div className="container-fluid">
                {applicationDetails &&
                <div className="row mt-3">
                    <div className="col-md-3">
                        
                                <div className="v2-img-container mb-3" style={{width:"100%"}}>
                                    <img className="v2-image"  src={applicationDetails.studentPhoto === null ? "/images/blank-profile.png" : applicationDetails.studentPhoto}/>
                                </div>
                                <LabelValue label="Name" value={applicationDetails.firstName+" "+applicationDetails.lastName} />
                                <LabelValue label="Class" value={getMasterLabel("class",applicationDetails.class)} />
                                <LabelValue label="Gender" value={getMasterLabel("gender",applicationDetails.gender)} />
                                <hr />
                                <h5>Student Signature</h5>
                                {
                                    applicationDetails.studentSignature ?
                                        <img style={{height:50,maxWidth:"100%"}} src={applicationDetails.studentSignature} />
                                    :
                                        <h6>Not Available</h6>
                                }
                                <h5>Parent Signature</h5>
                                {
                                    applicationDetails.parentSignature ?
                                        <img style={{height:50,maxWidth:"100%"}} src={applicationDetails.parentSignature} />
                                    :
                                        <h6>Not Available</h6>
                                }
                                <hr />
                                <h5 className="mt-3">Address</h5>
                                <p>{applicationDetails.addressLine1}, {applicationDetails.addressLine2}<br/>
                                {applicationDetails.city}, {getMasterLabel("state",applicationDetails.state)}, {applicationDetails.pincode}</p>
                                <hr />
                                <h5 className="mt-3">Documents</h5>
                                {
                                    applicationDetails.sportsCertificate!==null ?
                                        <a className="btn btn-link" href={applicationDetails.sportsCertificate}>Sport Certificate</a>
                                    :
                                        <h6>Sport Certificate Not Available</h6>
                                }
                                {
                                    applicationDetails.birthCertificate!==null ?
                                        <a className="btn btn-link" href={applicationDetails.birthCertificate}>Birth Certificate</a>
                                    :
                                        <h6>Birth Certificate Not Available</h6>
                                }
                    </div>
                    <div className="col-md-9">
                        <div className="card">
                            <div className={ applicationDetails.requestStatus === 2 ? "card-header bg-success text-white" : "card-header bg-warning"}>
                                <span className="float-start fw-bold">
                                    {applicationDetails.firstName+" "+applicationDetails.lastName}
                                </span>
                                <span className="float-end fw-bold">
                                    {
                                        applicationDetails.requestStatus === 2 ? "Payment Done" : "Payment Pending"
                                    }
                                </span>
                                {applicationDetails.requestStatus === 2 &&
                                    <Link className="btn btn-sm btn-primary text-white ms-2" to={"/admit-card/"+id} > Admit Card</Link>
                                }
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    
                                    { applicationDetails.imPaymentStatus !== null &&
                                        <div className="col-md-12">
                                            <h5>Payment Via Instamojo</h5>
                                            <h6>Instamojo Payment ID</h6>
                                            <h6 className="text-success">{applicationDetails.imPaymentId}</h6>
                                            <hr/>
                                        </div>
                                    }

                                    { applicationDetails.sbiTransectionNumber !== null &&
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5>Payment Received Via SBI</h5>
                                                    <h6>SBI Transection Number</h6>
                                                    <h6 className="text-success">{applicationDetails.sbiTransectionNumber}</h6>
                                                </div>
                                                <div className="col-md-4">
                                                    {applicationDetails.requestStatus === 3 &&
                                                        <button type="button" className="btn btn-primary" onClick={()=>setDialogStatus(true)} >Approve Payment</button>
                                                    }
                                                    {applicationDetails.requestStatus === 2 &&
                                                        <div className="alert alert-success"> <CheckCircle/> Approved</div>
                                                    }
                                                </div>
                                            </div>
                                            <hr/>
                                        </div>
                                    }
                                    
                                    {( applicationDetails.requestStatus === 1 || applicationDetails.requestStatus === 3) && (applicationDetails.sbiTransectionNumber === null || applicationDetails.sbiTransectionNumber === "") &&
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h6>ADD SBI Transection Number and </h6>
                                                </div>
                                                <div className="col-md-4">
                                                    <button type="button" className="btn btn-primary" onClick={()=>setSBIDialogStatus(true)} >Manully approve</button>
                                                </div>
                                            </div>
                                            <hr/>
                                        </div>

                                    }
                                    
                                    <div className="col-md-12">
                                        <LabelValue label="Application ID" value={applicationDetails.id} />
                                        <LabelValue label="UID / Aadhar no" value={applicationDetails.aadhar} />
                                        <LabelValue label="Identification Mark 1" value={applicationDetails.identificationMark1} />
                                        <LabelValue label="Identification Mark 2" value={applicationDetails.identificationMark2} />
                                    </div>
                                </div>
                                <h4 className="mt-3 text-success">Eligblity Details</h4>
                                <hr />
                                <LabelValue label="Date of Birth" value={applicationDetails.dob} />
                                <LabelValue label="Birth Place" value={applicationDetails.birthPlace} />
                                <LabelValue label="Birth Certificate No" value={applicationDetails.birthCertificateNo} />
                                <LabelValue label="Birth Certificate Issue Date" value={applicationDetails.birthCertificateIssueDate} />
                                <LabelValue label="Haryana Domicile" value={getMasterLabel("yes-no",applicationDetails.haryanaDomicile)}  />
                                {applicationDetails.haryanaDomicile == 1 &&
                                    <LabelValue label="Category" value={getMasterLabel("category-haryana",applicationDetails.category)}  />
                                }
                                {applicationDetails.haryanaDomicile == 2 &&
                                    <LabelValue label="Category" value={getMasterLabel("category",applicationDetails.category)}  />
                                }
                                <h4 className="mt-3 text-success">Parents Details</h4>
                                <hr />
                                <LabelValue label="Father Name" value={applicationDetails.fatherName} />
                                <LabelValue label="Father Phone" value={applicationDetails.fatherPhone} />
                                <LabelValue label="Father Occupation" value={getMasterLabel("occupation",applicationDetails.fatherOccupation)}  />
                                <LabelValue label="Mother Name" value={applicationDetails.motherName} />
                                <LabelValue label="Mother Phone" value={applicationDetails.motherPhone} />
                                <LabelValue label="Mother Occupation" value={getMasterLabel("occupation",applicationDetails.motherOccupation)}  />
                                <h4 className="mt-3 text-success">Sports & Quota Details</h4>
                                <hr />
                                <LabelValue label="Entry Type" value={getMasterLabel("entry-type",applicationDetails.entryType)}  />
                                {applicationDetails.entryType == 2 &&
                                    <>
                                        <LabelValue label="OSP (Outstanding sports person category)" value={getMasterLabel("osp",applicationDetails.osp)}  />
                                        {applicationDetails.gender == 1 &&
                                            <LabelValue label="Game" value={getMasterLabel("game-boys",applicationDetails.game)}  />
                                        }
                                        {applicationDetails.gender == 2 &&
                                            <LabelValue label="Game" value={getMasterLabel("game",applicationDetails.game)}  />
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        { /* JSON.stringify(applicationDetails) */}
                    </div>
                </div>
                }
            </div>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >
                {applicationDetails &&
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-warning text-center">
                                    Please Verify these details
                                </div>
                                <div className="alert alert-primary">
                                    <h3>Application Form No : {applicationDetails.id}</h3>
                                </div>
                                <div className="alert alert-primary">
                                    <h3>SBI Transection # : {applicationDetails.sbiTransectionNumber}</h3>
                                </div>
                                <div className="alert alert-primary">
                                    
                                    <LabelValue label="Student Name" value={applicationDetails.firstName+" "+applicationDetails.lastName} />
                                    <LabelValue label="Father Name" value={applicationDetails.fatherName} />
                                    <LabelValue label="Father Phone" value={applicationDetails.fatherPhone} />
                                    <LabelValue label="Mother Phone" value={applicationDetails.motherPhone} />

                                    {applicationDetails.haryanaDomicile == 1 &&
                                        <LabelValue label="Category" value={getMasterLabel("category-haryana",applicationDetails.category)}  />
                                    }
                                    {applicationDetails.haryanaDomicile == 2 &&
                                        <LabelValue label="Category" value={getMasterLabel("category",applicationDetails.category)}  />
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button type="button" className="btn btn-outline-primary" onClick={()=>setDialogStatus(false)} >Cancel</button>
                                <button type="button" className="btn btn-primary float-end" onClick={()=>confirmPayment(2)} >Confirm Payment</button>
                            </div>
                        </div>
                    </DialogContent>
                }
            </Dialog>

            <ApproveWithSBI
                getApplicationDetails={getApplicationDetails}
                applicationDetails={applicationDetails} 
                SBIDialogStatus={SBIDialogStatus} setSBIDialogStatus={setSBIDialogStatus}
            />
        </AppLayout>
    )
}

export default withAlert(AdminApplicationDetailsPage)