import { Dialog, DialogContent, LinearProgress } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import admitCardServices from '../admitCardServices';
import AdmitCardComponent from '../components/AdmitCardComponent';

function DownloadAdmitCardPage() {

    const [dialogStatus, setDialogStatus] = useState(true);
    const [phoneNo,setPhoneNo]  =   useState("");
    const [loading,setLoadingStatus] = useState(false);

    const [admitCardList,setAdmitCardList] =  useState(null);

    const searchByPhone = () =>{
        setLoadingStatus(true);
        admitCardServices.searchByPhone(phoneNo).then(res=>{
            setAdmitCardList(res.data);
        }).catch(error=>{

        }).finally(()=>{
            setLoadingStatus(false);
            setDialogStatus(false);
        })
    }

    return (
        <>
            <nav className="navbar shadow navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src="/global-logo.png" height="50" alt="" />
                    </Link>
                    

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link text-primary" href="/documents/admission-notice-2024-25.pdf" target="_blank">Admission Notice</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-primary" href="/documents/MNSS-Prospectus-English-2024-2025.pdf" target="_blank">MNSS Prospectus</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-primary" href="/documents/OSP-Prospectus-2024-25.pdf" target="_blank">OSP Prospectus</a>
                            </li>
                        </ul>
                        </div>
                </div>
            </nav>

            <div className="container-fluid">
                {dialogStatus == false &&
                    <>
                        {admitCardList && admitCardList.map(applicationDetails=>
                                <AdmitCardComponent applicationDetails={applicationDetails} />
                        )}
                        {admitCardList.length === 0 &&
                            
                            <div class="alert alert-danger mt-5" role="alert">
                                <h3>No Record found</h3>
                                <p>Please Verify Phone Number And <button className="btn btn-danger" onClick={()=>setDialogStatus(true)} >Try Again</button></p>
                            </div>
                        }
                    </>
                    
                }
                <Dialog
                    open={dialogStatus}
                    keepMounted
                    fullWidth={false}
                    maxWidth="md"
                >
                    <DialogContent>
                        <div className="col-md-12">
                            Enter Registered Father's Phone Number :
                        </div>
                        <div className="col-md-12">
                            {loading ?
                                <LinearProgress/>
                                :
                                <div className="input-group input-group-lg mb-3">
                                    <span className="input-group-text">Phone No </span>
                                    <input type="text" className="form-control" onChange={(e)=>setPhoneNo(e.target.value)} />
                                    <button className="btn btn-outline-primary" type="button" onClick={searchByPhone}>Search</button>
                                </div>
                            }
                        </div>
                        
                    </DialogContent>
                </Dialog>
            </div>
        </>
    )
}

export default DownloadAdmitCardPage