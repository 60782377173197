import React, { useEffect,useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import AdmissionInformation from '../../components/sections/AdmissionInformation';

function LandingPage() {

    const [admissionOngoingStatus,setAdmitionOngoingStatus] = useState(true);

    return (
        <>
            <nav className="navbar shadow navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src="/global-logo.png" height="50" alt="" />
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link text-primary" href="/documents/admission-notice-2024-25.pdf" target="_blank">Admission Notice</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-primary" href="/documents/MNSS-Prospectus-English-2024-2025.pdf" target="_blank">MNSS Prospectus</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-primary" href="/documents/OSP-Prospectus-2024-25.pdf" target="_blank">OSP Prospectus</a>
                            </li>
                        </ul>
                        </div>
                </div>
            </nav>

            <div className="container-fluid">
                {admissionOngoingStatus == true ?
                
                
                    <div className="row mt-5" >
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    <AdmissionInformation/>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            {
                            <div className="card">
                                <div className="card-body text-center">
                                    <Link to="/admit-card/download" className="btn btn-danger btn-lg blink_me" >Donwload Admit Card</Link>
                                </div>
                            </div>
                            }
                            {/*
                            <div className="card">
                                <div className="card-body">
                                    <Link to="/auth/login" className="btn btn-primary float-end" >Login</Link>
                                    <h6>New application or check your application status</h6>
                                </div>
                            </div>
                            */}
                            
                        </div>

                        <div className="col-md-4">
                            
                        </div>
                    </div>
                :
                    <div className="row">
                        <div className="col-md-12 text-center mt-3">
                            <div className="alert alert-primary">
                                <h3 className="text-primary blink_me">New admission dates will be updated soon</h3>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default LandingPage