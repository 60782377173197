import api from "../../../api/api";

const getList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/user/applicantion/details").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getById = (applicationId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/user/applicantion/details/"+applicationId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/user/applicantion/details",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateInline = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/user/applicantion/details",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const initiatePaymentRequest = (applicationId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/payment/initiate/"+applicationId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getPaymentStatus = (paymentRequestId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/payment/status/"+paymentRequestId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const sendVerificationRequest = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/user/applicantion/request-verification").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const submitSbiTransectionNumber = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/user/applicantion/details/sbi-transction",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const applicationServices = {
    getList,
    getById,
    save,
    updateInline,
    initiatePaymentRequest,
    getPaymentStatus,
    submitSbiTransectionNumber
    //sendVerificationRequest,
}
export default applicationServices;