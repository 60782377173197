import { Cancel, CancelRounded, CheckCircle } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent,  IconButton } from '@mui/material';
import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router';
import withAlert from '../../../../hoc/withAlert';
import AppLayout from  '../../../../layout/components/AppLayout'
import adminApplicantServices from '../adminApplicantServices';


const LabelValue= ({label,value}) =>{
    return(
        <div className="row">
            <div className="col-md-6"><strong>{label} : </strong></div>
            <div className="col-md-6"><div><span>{(value === null || value === "" ) ? "--NA--" : value }</span></div></div>
        </div>
    )
}

function ApplicantDetailsPage({AppAlert}) {

    let { id } = useParams();
    const [applicantDetails,setApplicantDetails]    = useState(null);
    const [loading, setLoadingStatus]           = useState(false);

    const getApplicantDetails = () =>{
        setLoadingStatus(true);
        adminApplicantServices.getById(id).then(res=>{
            setApplicantDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getApplicantDetails();
    },[id])


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-3">
                    </div>
                    {applicantDetails &&
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <LabelValue label="Applicant Name" value={applicantDetails.firstName+" "+applicantDetails.lastName} />
                                        <LabelValue label="Phone" value={applicantDetails.phone} />
                                        <LabelValue label="Email" value={applicantDetails.email} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
      </AppLayout>
    )
}

export default withAlert(ApplicantDetailsPage)