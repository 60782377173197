import React from 'react'
import AppLayout from '../../../../layout/components/AppLayout'

function RegistrationClosedPage() {
    return (
        <AppLayout>
            <div className="row mt-2">
                <div className="col-md-12 p-5">
                    <div className="alert alert-danger" role="alert">
                        Fresh registrations for the Session 2024-25  are now closed. Thanking you for visiting the website. Keep visit website regularly for more latest updates.
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default RegistrationClosedPage