import React,{useState,useEffect} from 'react'
import { Dialog, DialogTitle, DialogContent,  IconButton, LinearProgress } from '@mui/material';
import adminApplicationServices from '../adminApplicationServices';


const LabelValue= ({label,value}) =>{
    return(
        <div className="row">
            <div className="col-md-6"><strong>{label} : </strong></div>
            <div className="col-md-6"><div><span>{(value === null || value === "" ) ? "--NA--" : value }</span></div></div>
        </div>
    )
}

function ApproveWithSBI({applicationDetails,SBIDialogStatus, setSBIDialogStatus,getApplicationDetails }) {

    const [sbiTransectionNumber,setSbiTransectionNumber] = useState("")
    const [loading,setLoadingStatus] = useState(false);

    const confirmWithSbi = (requestStatus) =>{
        setLoadingStatus(true);
        let data = {
            id:applicationDetails.id,
            requestStatus:requestStatus,
            sbiTransectionNumber:sbiTransectionNumber,
        }
        
        console.log(data);
        
        adminApplicationServices.approveWithSBI(data).then(res=>{
            setSBIDialogStatus(false);
            getApplicationDetails();
        }).catch(error=>{
            console.log(error);
        }).finally(()=>{
            setLoadingStatus(false)
        })
        
        

    }

    return (
            <Dialog
                open={SBIDialogStatus}
                keepMounted
                onClose={()=>setSBIDialogStatus(false)}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >
                {applicationDetails &&
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-warning text-center">
                                    Please Verify these details
                                </div>
                                <div className="alert alert-primary">
                                    <h3>Application Form No : {applicationDetails.id}</h3>
                                </div>
                                
                                <div className="form-floating">
                                    <input type="text" className="form-control"  id="sbiInput"
                                        value={sbiTransectionNumber}
                                        onChange={(e)=>setSbiTransectionNumber(e.target.value)}

                                    />
                                    <label for="sbiInput">SBI Transection No : </label>
                                </div>
                                <div className="alert alert-primary">
                                    
                                    <LabelValue label="Student Name" value={applicationDetails.firstName+" "+applicationDetails.lastName} />
                                    <LabelValue label="Father Name" value={applicationDetails.fatherName} />
                                    <LabelValue label="Father Phone" value={applicationDetails.fatherPhone} />
                                    <LabelValue label="Mother Phone" value={applicationDetails.motherPhone} />

                                </div>
                            </div>
                            {!loading ?
                                <div className="col-md-12">
                                    <button type="button" className="btn btn-outline-primary" onClick={()=>setSBIDialogStatus(false)} >Cancel</button>
                                    <button type="button" disabled={sbiTransectionNumber === ""} className="btn btn-primary float-end" onClick={()=>confirmWithSbi(2)} >Confirm Payment</button>
                                </div>
                                :
                                <div className="col-md-12">
                                    <LinearProgress />
                                </div>
                            }
                            
                        </div>
                    </DialogContent>
                }
            </Dialog>
  )
}

export default ApproveWithSBI