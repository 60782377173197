import React,{useState} from 'react'
import { CircularProgress, Dialog, DialogContent, LinearProgress } from "@mui/material";
import applicationServices from '../applicationServices';
import { YouTube } from '@mui/icons-material';

function SBICollect({applicationDetails,getApplicationDetails}) {

    const [dialogStatus, setDialogStatus] = useState(false);
    const [trxFieldStatus,setTrxFieldStatus] = useState(false);
    const [sbiTransectionNumber,setSbiTransectionNumber] = useState("");
    const [loading,setLoadingStatus] = useState(false);

    const submitSbiTransectionNumber = () =>{
        let data = {
            id:applicationDetails.id,
            sbiTransectionNumber:sbiTransectionNumber,
        }
        setLoadingStatus(true);
        
        applicationServices.submitSbiTransectionNumber(data).then(res=>{
            setDialogStatus(false);
            getApplicationDetails();
            //console.log(res);
        }).catch(error=>{
            console.log(error)
        }).finally(()=>{
            setLoadingStatus(false)
        })
        
    }

    return (
        <>
            <button type="button" className="btn btn-info btn-lg" onClick={()=>setDialogStatus(true)}>Pay Via SBI Collect</button>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="alert alert-primary">
                            <div><a href="/documents/mnssrai-sbi-collect-instructions.pdf" target="_blank"> <h5>How to pay via SBI Collect</h5></a></div>
                            <div>
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/7q2_tETqraI?si=sh8_pDlDLSih73iu&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                    {trxFieldStatus == false &&
                        <div className="col-md-12 text-center">
                            <button type="button" className="btn btn-primary" onClick={()=>setTrxFieldStatus(true)}>Make Payment</button>
                        </div>
                    }
                    {trxFieldStatus == true &&
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className="alert alert-light">Your Application Form No : </h6>
                                </div>
                                <div className="col-md-6">
                                    <h6 className="alert alert-primary">{applicationDetails.id}</h6>
                                </div>
                                <div className="col-md-12 text-center">
                                    <div className="alert alert-info">Use this <b>Application Form No</b> with SBI Collect form</div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <a href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm" className="btn btn-info mb-3" target="_blank">Go to SBI Collect</a>
                                </div>
                            </div>

                            {!loading &&
                                <input type="text" className="form-control" placeholder="Enter SBI Transection # Here" 
                                    onChange={(e)=>setSbiTransectionNumber(e.target.value)}
                                    value={sbiTransectionNumber}
                                />
                            }
                            {!loading &&
                                <>
                                <button type="button" disabled={sbiTransectionNumber == ""} className="btn btn-primary mt-3 float-end" onClick={submitSbiTransectionNumber}> Submit Transection #</button>
                                <button type="button" className="btn btn-outline-primary mt-3" onClick={()=>setDialogStatus(false)}> Cancel</button>
                                </>
                            }
                        </div>
                    }
                    {loading && 
                        <div className="text-center">
                            <CircularProgress />
                        </div>
                    }
                </div>
            </DialogContent>

        </Dialog>

        </>
    )
}

export default SBICollect