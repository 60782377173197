import { AccountCircle, AccountTree, AirportShuttle, Badge, BusAlert, CalendarMonth, CalendarMonthOutlined, CalendarViewMonth, CalendarViewMonthOutlined, Contacts, CurrencyRupeeTwoTone, DirectionsBus, DocumentScanner, Dvr, FilterNone, Fingerprint, ForumSharp, GridOn, Groups, Home,  HomeWorkOutlined,  Label,  LiveHelp,  ManageAccounts, ManageHistory, Money, MoreVert, Newspaper, Notifications, PendingActions, People, Percent, PersonAdd, PersonOff, PersonRounded, RecentActors, Rule, School, Settings, TableChart, TextSnippet, Toc, TransferWithinAStation, WorkOutlined} from '@mui/icons-material';
import React, {useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { Link, NavLink } from 'react-router-dom'
import applicantDashboardServices from '../../dashboard/applicantDashboardServices';


export default function Header() {
    
    const authUser = useSelector((state) => state.auth)
    const userId=  authUser.id;




    const [applicantDetails,setApplicantDetails] = useState(null);
    const getDetails = () =>{
        applicantDashboardServices.getDetails().then(res=>{
            setApplicantDetails(res.data);
        })
    }
   
    useEffect(()=>{
        if(authUser.role == 4){
            getDetails();
        }
    },[authUser])

    return (
        <>  { userId && 
            <nav className="navbar shadow navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src="/global-logo.png" height="50" alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"14px"}}>
                                { authUser.role === 1 && <>

                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                            <School/> Applicant
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            <li><NavLink className="dropdown-item" to={"/applicant/list"} >Applicant List</NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                            <School/> Application
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            <li><NavLink className="dropdown-item" to={"/application/list/all"} >All</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/application/list/pending"} >Payment Pending</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/application/list/sbi-pending"} >SBI Pending</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/application/list/paid"} >Payment Approved</NavLink></li>
                                        </ul>
                                    </li>
                                    
                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                            <HomeWorkOutlined/> School
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            <li><NavLink className="dropdown-item" to={"/settings/school"} >Manage School</NavLink></li>
                                        </ul>
                                    </li>
                                    

                                </>}


                                {/* authUser.role === 4  && <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link active" aria-current="page" to="/application/list"> <Newspaper/> Application</NavLink>
                                    </li>
                                </>*/}

                        </ul>
                        
                        <ul className="navbar-nav">
                            
                       

                            { authUser.role === 1 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVert/> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    <li><NavLink className="dropdown-item" to="/system-master/list"><Label /> Status Master</NavLink></li>
                                    {/* <li><NavLink className="dropdown-item" to="/user/list"><ManageAccounts/> Manage User Accounts</NavLink></li> */}
                                    {/* <li><NavLink className="dropdown-item" to="/user/role"><Settings/> Manage User Role</NavLink></li> */}
                                </ul>
                            </li>
                            }



                            <li className="nav-item dropdown">
                                <a className="btn btn-danger" style={{borderRadius:"50px"}} href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                    {authUser.firstName.charAt(0)}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                    { authUser.role == 4 && <li><a className="dropdown-item" href="/my/profile">My Profile</a></li> }
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        }
        </>
    )
}
