import React,{useEffect,useState,useRef} from 'react'
import AppLayout from  '../../../../layout/components/AppLayout'
import adminApplicationServices from '../adminApplicationServices';
import useMasterList from '../../../../hooks/useMasterList'

import { ArrowRightAltRounded, CancelRounded, CheckCircle, Panorama } from '@mui/icons-material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import { LinearProgress } from '@mui/material';
import { FileDownload } from '@mui/icons-material';

import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import applicantDashboardServices from '../../../../dashboard/applicantDashboardServices';

const CellLink = (props) =>{
    return (
        <>
            {props && <Link to={`/application/details/${props.data.id}`}>{props.data.firstName} {props.data.lastName}</Link> }
        </>
    )
}

const CellStatus = (props) =>{
    return (
        <>
            {props.data.status == 1 ?
                <CheckCircle color="success"  />
                :
                <CancelRounded sx={{ color: "#F00" }} />
            }
        </>
    )
}


function ApplicationListPage() {
    const [applicationList,setApplicationList] = useState([]);
    const [masters,getMasterLabel] = useMasterList(['class','gender','state','yes-no','game',"osp","entry-type","category","category-haryana"]);
    const gridRef = useRef();
    let { listType } = useParams();

    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    })


    const [columnDefs,setColumnDefs] = useState([]);
    const setupColDef = () =>{
        setColumnDefs([
            {headerName: "Application #",field:"id"},
            {field:"updated_at", headerName:"Application Date", valueGetter: params => moment(params.data.updated_at).format("DD-MM-YYYY"),width:180 },
            {headerName: "Roll No",field:"rollNo"},
            {headerName: "PESAT",field:"pesat", valueGetter :params => params.data.pesat == null ? "NA" : moment(params.data.pesat).format("ll") ,width:180 },
            {field:"Name", valueGetter: params => params.data.firstName+" "+params.data.lastName, width:180,cellRenderer: CellLink, },
            {field:"fatherName" },
            {field:"fatherPhone" },
            {field:"aadhar",width:180 },
            {field:"category",width:180,valueGetter :params => params.data.haryanaDomicile === 1 ? getMasterLabel("category-haryana", params.data.category) : getMasterLabel("category", params.data.category)  },
            {field:"email", cellStyle: { 'textTransform': 'lowercase' }, },
            {field:"gender",valueGetter: params => getMasterLabel("gender", params.data.gender)},
            {field:"class",valueGetter: params => getMasterLabel("class", params.data.class)},
            {field:"dob",valueGetter: params => moment(params.data.dob).format("L") },
            {field:"dob", headerName:"DD-MM-YYYY (DOB)",valueGetter: params => moment(params.data.dob).format("DD-MM-YYYY"), width:180 },
            {field:"age", headerName:"Age on (2024-03-31)",valueGetter: params => moment("2024-03-31").diff(params.data.dob,"years"), width:180 },
            {field:"haryanaDomicile",valueGetter: params => getMasterLabel("yes-no", params.data.haryanaDomicile)},

            {field:"addressLine1" },
            {field:"addressLine2" },
            {field:"city" },
            {field:"state" ,valueGetter: params => getMasterLabel("state", params.data.state) },
            {field:"pincode" },
            {field:"imPaymentStatus", headerName:"Paid Via Instamojo" ,valueGetter: params => params.data.imPaymentStatus ? "Yes" : "No" },
            {field:"sbiPaymentDate", headerName:"Paid Via SBI" ,valueGetter: params => params.data.sbiPaymentDate ? "Yes" : "No" },
            //{field:"sbiPaymentDate", headerName:"SBI Payment Date" ,valueGetter: params => moment(params.data.sbiPaymentDate).format("ddd, D-M-Y") },
            {field:"entryType",valueGetter: params => getMasterLabel("entry-type", params.data.entryType)},
            {field:"osp",valueGetter: params => getMasterLabel("osp", params.data.osp)},

            {field:"haryanaDomicile",valueGetter: params => getMasterLabel("yes-no", params.data.haryanaDomicile)},
            {field:"game",valueGetter: params => getMasterLabel("game", params.data.game)},
        ]);
    }

    const onGridReady =(params)=>{
        getApplicationList();
    }

    useEffect(()=>{
        getApplicationList();
    },[listType])

    const [loading,setLoadingStatus] = useState(true);

    const getApplicationList = () =>{
        setLoadingStatus(true);
        adminApplicationServices.getList(listType).then(res=>{
            setApplicationList(res.data);
            setLoadingStatus(false);
            setupColDef()
        }).catch(error=>{
            setApplicationList([]);
        })
    }

    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv();
    }


    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                {masters && 
                    <div className="row">
                        <div className="col-md-9 mb-2">
                            <h3>Application List <ArrowRightAltRounded/> <span className="text-primary"> {listType.toUpperCase()} </span></h3>
                        </div>
                        <div className="col-md-3 mb-2">
                            <button type="button" onClick={onBtnExport} className="btn btn-primary float-end"> <FileDownload/> Export</button>
                        </div>
                        <div className="col-md-12">
                        {loading && <LinearProgress  /> }
                        </div>
                        <div className="col-md-12">
                        <div className="ag-theme-alpine" style={{height: 550, width:"auto"}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={applicationList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    onGridReady={onGridReady}
                                    >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default ApplicationListPage