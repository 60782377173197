import React,{useState,useEffect} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import applicantProfileServices from '../applicantProfileServices';
import TextInline from '../../../../components/InlineEdit/TextInline';
import ImageInline from '../../../../components/InlineEdit/ImageInline';
import withAlert from '../../../../hoc/withAlert';
import UpdateProfileForm from '../components/UpdateProfileForm';
import { Link } from 'react-router-dom';
import { Cancel, CancelRounded, CheckCircle } from '@mui/icons-material';



const LabelValue= ({label,value}) =>{
    return(
        <div className="row">
            <div className="col-md-6"><strong>{label} : </strong></div>
            <div className="col-md-6"><div><span>{(value === null || value === "" ) ? "--NA--" : value }</span></div></div>
        </div>
    )
}

function ApplicantProfilePage({AppAlert}) {

    const [applicantDetails,setApplicantDetails] = useState(null);
    const getDetails = () =>{
        applicantProfileServices.getDetails().then(res=>{
            setApplicantDetails(res.data);
        })
    }
   
    useEffect(()=>{
        getDetails();
    },[])

    const resendRequest = () =>{
        applicantProfileServices.sendVerificationRequest().then(res=>{
            getDetails();
        })
    }

    return(
        <AppLayout>
            <div className="container-fluid">
                {applicantDetails &&
                    <div className="row mt-3">
                        
                        <div  className="col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <span className="fw-bold"> My Profile </span>
                                    <span className="float-end">
                                        <UpdateProfileForm
                                            data={applicantDetails}
                                            AppAlert={AppAlert}
                                            refreshList={getDetails}
                                        />
                                    </span>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LabelValue label="Email" value={applicantDetails.email} />
                                            <LabelValue label="Appicant Name" value={applicantDetails.firstName+" "+applicantDetails.lastName} />
                                            <LabelValue label="Phone" value={applicantDetails.phone} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default withAlert(ApplicantProfilePage)



/*

<div className="row">
                                <div className="col-md-3">
                                    <span className="fw-bold">Name</span>
                                </div>
                                <div className="col-md-9">
                                    <TextInline
                                        name="firstName" 
                                        editId={applicantDetails.id}
                                        value={applicantDetails.firstName}
                                        updateInline={applicantProfileServices.updateInline}
                                    />
                                    <TextInline
                                        name="lastName" 
                                        editId={applicantDetails.id}
                                        value={applicantDetails.lastName}
                                        updateInline={applicantProfileServices.updateInline}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <span className="fw-bold">School No</span>
                                </div>
                                <div className="col-md-9">
                                    <TextInline
                                        name="schoolNo" 
                                        editId={applicantDetails.id}
                                        value={applicantDetails.schoolNo}
                                        updateInline={applicantProfileServices.updateInline}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <span className="fw-bold">Batch</span>
                                </div>
                                <div className="col-md-9">
                                    <TextInline
                                        name="batch" 
                                        editId={applicantDetails.id}
                                        value={applicantDetails.batch}
                                        updateInline={applicantProfileServices.updateInline}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <span className="fw-bold">Email</span>
                                </div>
                                <div className="col-md-9">
                                    <TextInline
                                        name="email" 
                                        editId={applicantDetails.id}
                                        value={applicantDetails.email}
                                        updateInline={applicantProfileServices.updateInline}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <span className="fw-bold">Phone</span>
                                </div>
                                <div className="col-md-9">
                                    <TextInline
                                        name="phone" 
                                        editId={applicantDetails.id}
                                        value={applicantDetails.phone}
                                        updateInline={applicantProfileServices.updateInline}
                                    />
                                </div>
                            </div>


*/