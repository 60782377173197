import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router'
import AppLayout from '../../../../layout/components/AppLayout'
import { LinearProgress } from "@mui/material";
import ApplicationSteps from '../components/ApplicationSteps';
import applicationServices from '../applicationServices';
import { Link } from 'react-router-dom';

function ApplicationPaymentStatusPage() {
    let { paymentRequestId } = useParams();
    const [loading, setLoadigStatus] = useState(false);

    const [paymentDetails,setPaymentDetails] = useState(null);
    const getPaymentDetails = () =>{
        applicationServices.getPaymentStatus(paymentRequestId).then(res=>{
            setPaymentDetails(res.data);
        })
    }

    useEffect(()=>{
        getPaymentDetails();
    },[paymentRequestId])

    
    return (
    <AppLayout>
        <div className="container">
            
                {/*
                <div className="row mt-2">
                    <div className="col-md-12">
                        <ApplicationSteps activeStep={2} />
                    </div>
                </div>
                */}
            
            <div className="row mt-2">
                <div className="col-md-12">
                    {paymentDetails &&
                        <div className="card">
                            <div className="card-body text-center">
                                {/*JSON.stringify(paymentDetails) */}
                                {paymentDetails.payment_status.toLowerCase() === "failed" &&
                                    <div className="border rounder p-3 text-danger">
                                        <h3>Payment {paymentDetails.payment_status}</h3>
                                        <h5>Transection # :  {paymentDetails.payment_id} </h5>
                                        <Link to={"/application/payment/"+paymentDetails.applicationId} className="btn btn-primary">Try Again</Link>
                                    </div>
                                }
                                {paymentDetails.payment_status.toLowerCase() !== "failed" &&
                                    <div className="border rounder p-3 text-success">
                                        <h3>Payment {paymentDetails.payment_status} </h3>
                                        <h5>Transection # :  {paymentDetails.payment_id} </h5>
                                        <Link to={"/"+paymentDetails.applicationId} className="btn btn-primary">View Application</Link>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </AppLayout>
    
  )
}

export default ApplicationPaymentStatusPage