import React from 'react'

function AdmissionInformation() {
  return (
    <>
        <h6>MOTILAL NEHRU SCHOOL OF SPORTS, RAI-131029 DISTT SONIPAT (HARYANA) (Session 2024-25)</h6>
        <h4>(Session 2024-25) For Vacant Seats</h4>
        <h5 class="text-center">Important Instructions</h5>
        <h5>Admission Form (Class 5,6, 7, 8,9, 11)</h5>
        <ol>
            {/*
            <li>Total no of seats for Class V is 150, (75 Boys+75 Girls) for the session 2024-25.</li>
            <li>The vacant seats in other classes (VI to IX and XI) will be filled under Outstanding Sports Person Category/Lateral Entry.</li>
            */}

            <li>
                <table className="table">
                    <thead>
                        <tr>
                            <th colSpan="2">

                            AGE CRITERIA<br/>The age criteria must be strictly noted as follows<br/>
                            
                            
                            </th>
                        </tr>
                        <tr>
                            <th>Class</th>
                            <th>Age (Between)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>V</td>
                            <td>01.04.2013 - 31.03.2015</td>
                        </tr>
                        
                        <tr>
                            <td>VI</td>
                            <td>01.04.2012 - 31.03.2014</td>
                        </tr>
                        <tr>
                            <td>VII</td>
                            <td>01.04.2011 - 31.03.2013</td>
                        </tr>
                        <tr>
                            <td>VIII</td>
                            <td>01.04.2010 - 31.03.2012</td>
                        </tr>
                        
                        <tr>
                            <td>IX</td>
                            <td>01.04.2009 - 31.03.2011</td>
                        </tr>
                        
                        <tr>
                            <td>XI</td>
                            <td>01.04.2007 - 31.03.2009</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2">
                                In case the age criteria is not followed, the school authorities reserve all rights to cancel the candidature of the applicant and in that case the application fee will not be refundable.<br/>
                                Since the age verification is carried out by various Sports Federations / Associations on the basis of certificate of Date of Birth issued by Municipal Corporation /Registrar of Birth and Death hence date of birth certificate issued by Municipal Corporation/ Registrar, Birth and Death will be accepted only if the birth has been registered within 2 years from the date of birth.
                                <br/>
                            </td>

                        </tr>
                    </tfoot>
                </table>
            </li>
            <li>Last date of submission of online application form is  <strong>15.07.2024</strong></li>
            <li>Last date of submission of online application form with late fee is <strong>17.07.2024</strong></li>
            <li>The age and other details like name, father’s name, mother’s name etc. in the application form should match the age and details given in the birth certificate of the applicant.</li>
            <li>The photograph of the applicant to be uploaded must be latest i.e. taken on or after 01.10.2023. Name of the candidate and date of photograph should be mention on the photograph.</li>
            <li>The email id and the mobile no. provided by the applicant must be personal, correct and fully functional. The applicant can also give an alternate mobile number.</li>
            <li>The candidate must bring only coloured admit card on the exam date, the print out the admit card can be taken from the school website.</li>
            <li>An applicant’s form will be considered only when application form and application fee received successfully.</li>
            <li>School will not be responsible if application form is not filled properly.</li>
            <li>It must ensured by parent/guardian that the name of candidate, parent’s name being filled in the form should be the same as in the Birth certificate of the candidate. No changes will be made later since the CBSE does not endorse such changes during registration of students.</li>
            <li>Only recently issued residence certificate in the name of the child’s father will be valid. If the parents have mentioned in the application that they are residents of Haryana. Residence certificate in the name of mother/child is not acceptable.</li>
            <li>People in other states do not require this certificate.</li>
            <li>Discrepancies in admission form and birth certificate will lead to immediate cancellation of the form for which the applicant will be solely responsible.</li>
            <li>If any candidate having problem while submission of application form please email us at mnssadmission@gmail.com and if the fee of any application form comes more than once, it will not be refunded in any case by school and the applicant will be responsible for it.</li>
        </ol>
        <h6><strong className="mt-4 float-end">Principal & Director<br/>MNSS, Rai</strong></h6>
    </>
  )
}

export default AdmissionInformation