import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import useMasterList from '../../../../hooks/useMasterList';
import AppLayout from '../../../../layout/components/AppLayout';
import applicationServices from '../applicationServices';

const LabelValue = ({label,value}) =>{
    return (
        <div className="row">
            <div className="col-md-5 fw-bold">{label}</div>
            <div className="col-md-7">: {value}</div>
        </div>
    )
}

function ApplicationDetailsPage() {
    
    let { id } = useParams();
    const navigate = useNavigate();
    const [masters,getMasterLabel]  =  useMasterList(["area","class","gender","yes-no","state", "occupation","osp","game","game-boys","category","category-haryana","religion","nationality","caste"]);

    const [applicationDetails,setApplicationDetails] = useState(null);
    const getApplicationDetails = () =>{
        applicationServices.getById(id).then(res=>{
            setApplicationDetails(res.data);
        })
    }

    useEffect(()=>{
        getApplicationDetails();
    },[id]);

    useEffect(()=>{
        if(applicationDetails && applicationDetails.requestStatus == 1){
            navigate("/application/form?applicationId="+applicationDetails.id)
        }
    },[applicationDetails])

    return (
        <AppLayout>
            <div className="container-fluid">
                {applicationDetails &&
                <div className="row mt-3">
                    <div className="col-md-3">
                        
                                <div className="v2-img-container mb-3" style={{width:"100%"}}>
                                    <img className="v2-image"  src={applicationDetails.studentPhoto === null ? "/images/blank-profile.png" : applicationDetails.studentPhoto}/>
                                </div>
                                <LabelValue label="Name" value={applicationDetails.firstName+" "+applicationDetails.middleName+" "+applicationDetails.lastName} />
                                <LabelValue label="Class" value={getMasterLabel("class",applicationDetails.class)} />
                                <LabelValue label="Gender" value={getMasterLabel("gender",applicationDetails.gender)} />
                                <hr />
                                <h5>Student Signature</h5>
                                <img style={{height:50,maxWidth:"100%"}} src={applicationDetails.studentSignature} />
                                <h5>Parent Signature</h5>
                                <img style={{height:50,maxWidth:"100%"}} src={applicationDetails.parentSignature} />
                                <hr />
                                <h5 className="mt-3">Address</h5>
                                <p>{applicationDetails.addressLine1}, {applicationDetails.addressLine2}<br/>
                                {applicationDetails.city}, {getMasterLabel("state",applicationDetails.state)}, {applicationDetails.pincode}</p>
                                <hr />
                                <h5 className="mt-3">Documents</h5>
                                <a className="btn btn-link" href={applicationDetails.sportsCertificate}>Sport Certificate</a><br/>
                                <a className="btn btn-link" href={applicationDetails.	birthCertificate}>Birth Certificate</a>
                    </div>
                    <div className="col-md-9">
                        {applicationDetails.requestStatus === 3 &&
                                <div className="alert alert-warning">Your SBI transaction need to be verified, it would take 24 hrs.</div>
                        }

                        {applicationDetails.requestStatus === 2 &&
                            <div className="alert alert-success">Payment Received</div>
                        }
                        <div className="card">
                            <div className="card-header bg-success text-white fw-bold">{applicationDetails.firstName} {applicationDetails.middleName} {applicationDetails.lastName}</div>
                            <div className="card-body">
                                <LabelValue label="UID / Aadhar no" value={applicationDetails.aadhar} />
                                <LabelValue label="Identification Mark 1" value={applicationDetails.identificationMark1} />
                                <LabelValue label="Identification Mark 2" value={applicationDetails.identificationMark2} />
                                <h4 className="mt-3 text-success">Eligblity Details</h4>
                                <hr />
                                <LabelValue label="Date of Birth" value={applicationDetails.dob} />
                                <LabelValue label="Birth Place" value={applicationDetails.birthPlace} />
                                <LabelValue label="Birth Certificate No" value={applicationDetails.birthCertificateNo} />
                                <LabelValue label="Birth Certificate Issue Date" value={applicationDetails.birthCertificateIssueDate} />
                                <LabelValue label="Haryana Domicile" value={getMasterLabel("yes-no",applicationDetails.haryanaDomicile)}  />
                                {applicationDetails.haryanaDomicile == 1 &&
                                    <LabelValue label="Category" value={getMasterLabel("category-haryana",applicationDetails.category)}  />
                                }
                                {applicationDetails.haryanaDomicile == 2 &&
                                    <LabelValue label="Category" value={getMasterLabel("category",applicationDetails.category)}  />
                                }
                                <h4 className="mt-3 text-success">Parents Details</h4>
                                <hr />
                                <LabelValue label="Father Name" value={applicationDetails.fatherName} />
                                <LabelValue label="Father Phone" value={applicationDetails.fatherPhone} />
                                <LabelValue label="Father Occupation" value={getMasterLabel("occupation",applicationDetails.fatherOccupation)}  />
                                <LabelValue label="Mother Name" value={applicationDetails.motherName} />
                                <LabelValue label="Mother Phone" value={applicationDetails.motherPhone} />
                                <LabelValue label="Mother Occupation" value={getMasterLabel("occupation",applicationDetails.motherOccupation)}  />
                                <h4 className="mt-3 text-success">Sports & Quota Details</h4>
                                <hr />
                                <LabelValue label="Entry Type" value={getMasterLabel("entry-type",applicationDetails.entryType)}  />
                                {applicationDetails.entryType == 2 &&
                                    <>
                                        <LabelValue label="OSP (Outstanding sports person category)" value={getMasterLabel("osp",applicationDetails.osp)}  />
                                        {applicationDetails.gender == 1 &&
                                            <LabelValue label="Game" value={getMasterLabel("game-boys",applicationDetails.game)}  />
                                        }
                                        {applicationDetails.gender == 2 &&
                                            <LabelValue label="Game" value={getMasterLabel("game",applicationDetails.game)}  />
                                        }
                                    </>
                                }
                                
                                
                                {/*
                                <LabelValue label="Religion" value={getMasterLabel("religion",applicationDetails.religion)}  />
                                <LabelValue label="Nationality" value={getMasterLabel("nationality",applicationDetails.nationality)}  />
                                <LabelValue label="Caste" value={getMasterLabel("caste",applicationDetails.caste)}  />
                                */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        { /* JSON.stringify(applicationDetails) */}
                    </div>
                </div>
                }
            </div>
        </AppLayout>
    )
}

export default ApplicationDetailsPage