import React,{useRef} from 'react'
import { useReactToPrint } from 'react-to-print';

import moment from 'moment'
import useMasterList from '../../../../hooks/useMasterList';

const LabelValue= ({label,value}) =>{
    return(
        <div className="row">
            <div className="col-md-6"><strong>{label} : </strong></div>
            <div className="col-md-6"><div><span>{(value === null || value === "" ) ? "--NA--" : value }</span></div></div>
        </div>
    )
}

function AdmitCardComponent({applicationDetails}) {
    

    const [masters,getMasterLabel]  =  useMasterList(["area","class","gender","osp","game","game-boys","category","category-haryana","religion","nationality","caste"]);


    const printComponentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        //pageStyle:"@page { size: 2.5in 2.5in; margin: 0; padding:0 }, @media print { @page {  size: 2.5in 2.5in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }",
        //pageStyle:"@media print { html, body { height: 2.5in; /* Use 100% here to support printing more than a single page*/ margin: 0 !important;padding: 0 !important;overflow: hidden;}}; @page { size: 2.5in 2.5in; margin: 0; }"
        //onAfterPrint:()=>{navigate("/kiosk/dashboard")}
    });


    return (
        <>
            {applicationDetails &&
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <button className="btn btn-primary float-end" onClick={handlePrint} >Print</button>
                        </div>
                    </div>
                </div>
            }
            {applicationDetails &&
                <div className="container" ref={printComponentRef}>
                    <div className="row mt-3">
                        <div className="col-12 text-center">
                            <span className="navbar-brand">
                                <img src="/global-logo.png" height="50" alt="" />
                            </span>
                        </div>
                        <div className="col-12 text-center">
                            <h4 className="mt-3">ADMIT CARD</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="v2-img-container mb-3" style={{width:"100%"}}>
                                <img className="v2-image"  src={applicationDetails.studentPhoto === null ? "/images/blank-profile.png" : applicationDetails.studentPhoto}/>
                            </div>
                            <div className="text-center">
                            {
                                applicationDetails.studentSignature ?
                                    <img style={{height:50,maxWidth:"100%"}} src={applicationDetails.studentSignature} />
                                :
                                    <h6>Not Available</h6>
                            }
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-4">
                                    <div><strong>{applicationDetails.firstName+" "+applicationDetails.lastName}</strong></div>
                                    <div>{getMasterLabel("gender",applicationDetails.gender)}</div>
                                    <div>Class : {getMasterLabel("class",applicationDetails.class)}</div>
                                    <div>DOB : {moment(applicationDetails.dob).format("ll")}</div>
                                    <div>Age : {moment("2024-03-31").diff(applicationDetails.dob,"years")}</div>
                                    {applicationDetails.haryanaDomicile == 1 &&
                                        <div>{getMasterLabel("category-haryana",applicationDetails.category)}</div>
                                    }
                                    {applicationDetails.haryanaDomicile == 2 &&
                                        <div>{getMasterLabel("category",applicationDetails.category)} </div>
                                    }
                                </div>
                                <div className="col-8">
                                    {/* <LabelValue label="Application ID" value={applicationDetails.id} /> */}
                                    <LabelValue label="Roll No" value={applicationDetails.rollNo} />
                                    <LabelValue label="Date of Exam" value={moment(applicationDetails.pesat).format("ll")} />
                                    <LabelValue label="Father Name" value={applicationDetails.fatherName} />
                                    <LabelValue label="Mother Name" value={applicationDetails.motherName} />
                                    <LabelValue label="UID / Aadhar no" value={applicationDetails.aadhar} />
                                    <LabelValue label="Identification Mark" value={applicationDetails.identificationMark1}  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <h4>Instructions</h4>
                        </div>
                        <div className="col-12">
                            <ol>
                                <li> All parents are requested to bring their wards for PESAT (Physical Efficiency and Sports Aptitude Test) at Motilal Nehru School of Sports, Rai for the Session 2024-2025 as per schedule mentioned in Admit Card of their respective ward.</li>
                                <li> Your ward should report at <strong>06.30 a.m.</strong> and you can receive your coloured Admit Card on School website www.mnssrai.com.</li>
                                {/*<li> No parent/escort will be allowed to enter the School Campus during the test.</li>*/}
                                <li> On conclusion of the test, your ward will be handed over back to you at the School Main Gate.</li>
                                <li> Parents are advised to bring their wards for the test in P.T. Kit i.e. Shorts/ Knickers, T-shirt and P.T. Shoes with socks.</li>
                                <li> No request for change in date and/or time of the test will be entertained for any reason whatsoever including medical grounds.</li>
                                <li> The School will not provide any accommodation for overnight stay. Therefore, you are requested to make your own arrangements.</li>
                                <li> Please bring original applicant <strong>AADHAR CARD</strong> at the time of test.</li>
                                <li> If at any stage it is found that the birth registration of the applicant is not as per the criteria mentioned in the Admission Notice (2024-25). The applicant will not be considered for further admission process. Parents should ensure that the birth certificate of their child should be registered within <strong>two years</strong> of the date of birth.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            }
        </>
        
    )
}

export default AdmitCardComponent