
import { Badge, CheckCircle, Pending } from '@mui/icons-material';
import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import useMasterList from '../../hooks/useMasterList';
import AppLayout from '../../layout/components/AppLayout'
import applicantDashboardServices from '../applicantDashboardServices';

const LabelValue = ({label,value}) =>{
    return (
        <div className="row">
            <div className="col-md-4 fw-bold">{label}</div>
            <div className="col-md-8">: {value}</div>
        </div>
    )
}


function ApplicantDashboard() {
    const [applicantDetails,setApplicantDetails] = useState(null);
    const [applicationList,setApplicationList] = useState(null);

    const [masters,getMasterLabel]  =   useMasterList(["class"]);

    const getDashboardData = () =>{
        applicantDashboardServices.getDashboardData().then(res=>{
            setApplicantDetails(res.data.applicantDetails);
            setApplicationList(res.data.applicationList);
        })
    }

    useEffect(()=>{
        getDashboardData();
    },[])


    return (
        applicantDetails &&
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3 mb-3">
                    <div className="col-md-12">
                        <h3>Hello, <span className="text-success"> {applicantDetails.firstName} {applicantDetails.lastName} </span></h3>
                    </div>
                </div>
                <div className="row">
                {applicationList && applicationList.length === 0 &&
                    <div className="col-md-6">
                        <h5>You have no application yet, click on add new application, to start your registration</h5>
                    </div>
                }
                {applicationList && applicationList.length > 0 && applicationList.map(application=>
                    
                    <div className="col-md-6 mb-3" key={application.id}>
                        <div className="card">
                            <div className="card-header"><span className="fw-bold">Application # {application.id}</span></div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="v2-img-container" style={{width:"100%"}}>
                                            <img className="v2-image"  src={application.studentPhoto === null ? "/images/blank-profile.png" : application.studentPhoto}/>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <LabelValue label="Name" value={application.firstName+" "+application.lastName} />
                                        <LabelValue label="Class" value={getMasterLabel("class",application.class)} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                {
                                    /*
                                    application.studentPhoto === null || application.sportsCertificate || application.birthCertificate
                                    || application.studentSignature || application.parentSignature || 
                                    <Link to={"/application/document/"+application.id} className="btn btn-primary float-end" >Update Document</Link>
                                    */
                                }
                                { 
                                    /*
                                    This section commented after sbi collect intigration
                                    {application.imPaymentStatus != null && <button type="button" className="btn btn-success"> <CheckCircle /> Payment Received</button> } 
                                    */
                                }
                                {application.requestStatus == 2 && <button type="button" className="btn btn-success"> <CheckCircle /> Payment Received</button> } 
                                {application.requestStatus == 3 && <button type="button" className="btn btn-warning"> <Pending/> Payment In progress</button> } 
                                
                                {application.requestStatus == 1 && <Link to={"/application/form?applicationId="+application.id} className="btn btn-primary float-end" >Edit Details</Link>}
                                {application.requestStatus == 2 && <Link to={"/application/"+application.id} className="btn btn-warning float-end" >View Details</Link> }
                                {application.requestStatus == 3 && <Link to={"/application/"+application.id} className="btn btn-warning float-end" >View Details</Link> }
                                {application.requestStatus == 2 && <Link to={"/admit-card/"+application.id} className="btn btn-primary me-2 float-end" > <Badge/> Admit Card</Link> }
                            </div>
                        </div>
                    </div>
                )}

                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                 <Link to={"/application/form"} className="btn btn-primary" >New Application</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </AppLayout>
    )
}

export default ApplicantDashboard