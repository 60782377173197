import api from "../../../api/api";

const getDetails = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/user/applicant/details").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/user/applicant/details",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateInline = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/user/applicant/details",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const sendVerificationRequest = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/user/applicant/request-verification").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const applicantProfileServices = {
    getDetails,
    updateInline,
    save,
    sendVerificationRequest,
}
export default applicantProfileServices;