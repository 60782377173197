import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router'
import AppLayout from '../../../../layout/components/AppLayout'
import { CircularProgress } from "@mui/material";
import ApplicationSteps from '../components/ApplicationSteps';
import applicationServices from '../applicationServices';
import withAlert from '../../../../hoc/withAlert';
import SBICollect from '../components/SBICollect';

function ApplicationPaymentPage({AppAlert}) {
    let { id } = useParams();
    const [loading, setLoadigStatus] = useState(false);

    const [applicationDetails,setApplicationDetails] = useState(null);
    const getApplicationDetails = () =>{
        applicationServices.getById(id).then(res=>{
            setApplicationDetails(res.data);
        })
    }

    useEffect(()=>{
        getApplicationDetails();
    },[id])

    const initiatePayment = () =>{
        
        setLoadigStatus(true);
        applicationServices.initiatePaymentRequest(id).then(res=>{
            //console.log(res);
            window.location.href = res.imResponse.longurl;

            //Instamojo.open(res.imResponse.longurl);

        }).catch(error=>{
            AppAlert(JSON.stringify(error.msg),"error")
            setLoadigStatus(false)
        })
    }
    return (
    <AppLayout>
        <div className="container">
            
                <div className="row mt-2">
                    <div className="col-md-12">
                        <ApplicationSteps activeStep={2} />
                    </div>
                </div>
            
            <div className="row mt-2">
                <div className="col-md-12">
                {applicationDetails &&
                    <div className="card">
                        <div className="card-body text-center">
                            {(applicationDetails.requestStatus === 1) &&
                                <>
                                    {loading && <h6> <CircularProgress/><br/> Initiating payment Please do not press back or refresh button</h6>}
                                    {/*
                                        <>
                                        {!loading && <button type="button" className="btn btn-primary btn-lg me-3" onClick={initiatePayment}>Pay Via Instamojo</button> }
                                        </>
                                    */}
                                    
                                    {!loading && <SBICollect applicationDetails={applicationDetails} getApplicationDetails={getApplicationDetails} /> }
                                </>
                            }

                            {(applicationDetails.requestStatus === 3) &&
                                <>
                                    <div className="alert alert-warning"> 
                                        <div className="">Paid Via SBI Collect </div>
                                        <div className="">SBI Transection # : {applicationDetails.sbiTransectionNumber}</div>
                                        <div className="">Your SBI transaction need to be verified, it would take 24 hrs.</div>
                                    </div>
                                </>
                            }

                            {(applicationDetails.requestStatus === 2 && applicationDetails.sbiTransectionNumber !== null) &&
                                <>
                                    <div className="alert alert-success"> 
                                        <div className="">Payment Received </div>
                                        <div className="">SBI Transection # : {applicationDetails.sbiTransectionNumber}</div>
                                    </div>
                                </>
                            }

                            {(applicationDetails.requestStatus === 2 && applicationDetails.imPaymentStatus !== null) &&
                                <>
                                    <div className="alert alert-success">
                                        <h3>Payment Received</h3>
                                        <h5>Transaction # :  {applicationDetails.imPaymentId} </h5>
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                }
                </div>
            </div>
        </div>

        
        
    </AppLayout>
    
  )
}

export default withAlert(ApplicationPaymentPage)